import React from "react"
import PropTypes from "prop-types"
import ContentPreview, {
  ContentPreviewLayouts,
} from "../preview/ContentPreview"
import indexColumns from "../../utils/indexColumns"
import Fade from "react-reveal/Fade"
import _ from "lodash"
import moment from "moment"

import Arrow from "../../svg/Arrow"

/**
 * Work list container displaying results
 */
class WorkList extends React.Component {
  constructor(props) {
    super(props)

    // sort all work from data query
    this.sortedWork = this.sortWork()

    // set first in list as featured
    this.WorkFeatured = this.getWork(this.sortedWork, 0, 1)[0]

    // build index excluding WorkFeatured pick
    this.WorkIndex = this.getWork(this.sortedWork, 1, this.sortedWork.length)

    // column settings
    this.maxVisible = 7 // max visible before adding "show all button"
    this.columnMax = 6 // maximum number of column before reverting count to 1

    // create class instance for column number, delay, etc
    this.indexColumns = new indexColumns(0, this.columnMax)

    this.state = {
      hiddenClass: `is-hide`,
      loadMoreClass: `is-visible`,
      visible: 7,
    }

    this.showAll = this.showAll.bind(this)
  }

  sortWork() {
    // flatten all work
    let allWork =
      this.props.allWork && this.props.allWork.edges !== undefined
        ? this.props.allWork.edges
        : this.props.allWork

    let category = this.props.category

    // sort by date first
    allWork = _.sortBy(allWork, work => {
      // flatten node
      let _work = typeof work.node !== `undefined` ? work.node : work

      // convert date to unix timestamp
      let workDate = moment(_work.publishDate).unix()

      // sort by unix timestamp, reversed
      return workDate * -1
    })

    // now sort with sticky elements first
    allWork = _.sortBy(allWork, function(work) {
      // flatten node
      let _work = typeof work.node !== `undefined` ? work.node : work

      return _work.sticky === true ? 0 : 1
    })

    // now remove workdesign from all other categories
    allWork = _.remove(allWork, function(work) {
      // flatten node
      let _work =
        typeof work.node !== `undefined` && category !== `design`
          ? work.node
          : work

      return _work.workDesign !== true
    })

    //
    // NOTE: leave for testing sorting in console
    //
    // allWork.map((work, index) => {
    //   let _work = typeof work.node !== `undefined` ? work.node : work
    //   console.log(index, _work.name, _work.publishDate, _work.sticky);
    // })

    return allWork
  }

  getWork(list, slice = 0, length) {
    return _.slice(list, slice, length)
  }

  workColumn() {
    let className = []

    // 1st item and 4th item
    if (
      this.indexColumns.columnNumber === 1 ||
      this.indexColumns.columnNumber === 4
    ) {
      className.push(`col-12`)
      className.push(ContentPreviewLayouts.full)
    }

    // first element compact element, set "compact_fill" layout
    if (this.indexColumns.columnNumber === 2) {
      className.push(`col-12 col-md-6`)
      className.push(ContentPreviewLayouts.compact_fill)

      // all others are only "compact"
    } else if (
      this.indexColumns.columnNumber === 3 ||
      this.indexColumns.columnNumber > 4
    ) {
      className.push(`col-12 col-md-6`)
      className.push(ContentPreviewLayouts.compact)
    }

    // left offset every 5th
    if (this.indexColumns.columnNumber === 5) {
      className.push(ContentPreviewLayouts.compact_offset_left)
    }

    // and left/right gutters on some column numbers
    if (
      this.indexColumns.columnNumber === 2 ||
      this.indexColumns.columnNumber === 5
    ) {
      className.push(`right-gutter`)
    }
    if (this.columnNumber === 3 || this.columnNumber === 6) {
      className.push(`left-gutter`)
    }

    return className.join(` `)
  }

  workVisibity(index) {
    return index <= this.maxVisible - 1 ? `is-visible` : this.state.hiddenClass
  }

  listWork() {
    if (this.WorkIndex.length > 0) {
      // will hold content return
      let content = []

      // XXX: fixing hot reloader issue
      this.indexColumns.reset()

      // loop each work
      this.WorkIndex.slice(0, this.state.visible).map((work, index) => {
        this.indexColumns.increment()

        // push each work
        content.push(
          <ContentPreview
            key={index}
            index={index}
            contentData={work}
            contentType="work"
            layout={this.workColumn()}
            visible={`is-visible`}
            delay={this.indexColumns.getDelay()}
          />
        )

        // push toggle nav when maximum visible is reached
        if (index === this.state.visible - 1) {
          {this.state.visible < this.WorkIndex.length &&
            content.push(
              <Fade key={new Date()} top distance="15%">
                <nav
                  className={`col-12 nav-show-more ${this.state.loadMoreClass}`}
                  key={new Date()}
                >
                  <a href="#" className="btn__arrow" onClick={this.showAll}>
                    <span>Load more</span>
                    <Arrow direction="bottom" />
                  </a>
                </nav>
              </Fade>
            )
          }
        }
      })

      return content
    } else if (this.WorkFeatured == null && this.WorkIndex.length > 1) {
      return <h3>Nothing found... :(</h3>
    } else {
      return <div>{/* empty div */}</div>
    }
  }

  showAll(e) {
    e.preventDefault()
    this.setState((prev) => {
      return {visible: prev.visible + 30}
    })
  }

  render() {
    return (
      <section className="work__list">
        {/* header with first work item */} 
        <header className="work__list__featured_work row mb-5">
          {this.WorkFeatured && (
            <ContentPreview
              contentData={this.WorkFeatured}
              type="work"
              layout={`col-12 ${ContentPreviewLayouts.full} no-margin`}
            />
          )}
        </header>

        {/* filters nav here */}
        {this.props.ContentFilters()}

        {/* the rest */}
        <div className="row">{this.listWork()}</div>
      </section>
    )
  }
}

WorkList.propTypes = {
  allWork: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  ContentFilters: PropTypes.func.isRequired,
}

export default WorkList
